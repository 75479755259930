<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Сурталчилгаа</h2>
        </el-col>
        <el-col :span="12">
          <div class="actions text-right">
            <router-link to="/add-banner">
              <el-button type="success" class="item">Нэмэх</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabStatus" @tab-click="onClickTab">
        <el-tab-pane label="All" name="all">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="12">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="channel" @change="handleType">
                    <el-option
                      v-for="(type, typeIndex) in bannerChannelType"
                      :key="typeIndex"
                      :label="type.label"
                      :value="type.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="3" v-if="channel === 'web'">
                  <el-dropdown split-button type="text" trigger="click" @command="handleFillter">
                    {{this.page ? this.page : 'Байрлах хуудас'}}
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'dropdown.value' v-for="(dropdown, indexWeb) in dropdownData" :key="indexWeb">{{dropdown.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
                <el-col :span="3" v-else>
                  <el-dropdown split-button type="text" trigger="click" @command="handleFillter">
                    {{this.page ? this.page : 'Байрлах хуудас'}}
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'dropdown.value' v-for="(dropdown, indexMobile) in dropdownDataMobile" :key="indexMobile">{{dropdown.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
                <el-col :span="4">
                  <el-select size="mini" v-if="page === 'product'" v-model="fillterCategories" multiple placeholder="Категори сонгох" @change="handleCategories">
                    <el-option
                      v-for="(category, index) in categories"
                      :key="index"
                      :label="category.name_mon"
                      :value="category.uniq_id">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown  @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <banner-table :search='search' :loading='loading' :banners='banners' :pageSize='pageSize' :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler" :tab="activeTabStatus" :status="status"></banner-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Active" name="active">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="12">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="channel" @change="handleType">
                    <el-option
                      v-for="(type, typeIndex) in bannerChannelType"
                      :key="typeIndex"
                      :label="type.label"
                      :value="type.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4" v-if="channel === 'web'">
                  <el-dropdown split-button type="text" trigger="click" @command="handleFillter">
                    {{this.page ? this.page : 'Байрлах хуудас'}}
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'dropdown.value' v-for="(dropdown, indexWeb) in dropdownData" :key="indexWeb">{{dropdown.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
                <el-col :span="4" v-else>
                  <el-dropdown split-button type="text" trigger="click" @command="handleFillter">
                    {{this.page ? this.page : 'Байрлах хуудас'}}
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'dropdown.value' v-for="(dropdown, indexMobile) in dropdownDataMobile" :key="indexMobile">{{dropdown.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown  @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <banner-table :search='search' :loading='loading' :banners="banners" :pageSize='pageSize' :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler" :tab="activeTabStatus" :status="status"></banner-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Expired" name="expired">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="12">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search"  @input="onSearch">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="channel" @change="handleType">
                    <el-option
                      v-for="(type, typeIndex) in bannerChannelType"
                      :key="typeIndex"
                      :label="type.label"
                      :value="type.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4" v-if="channel === 'web'">
                  <el-dropdown split-button type="text" trigger="click" @command="handleFillter">
                    {{this.page ? this.page : 'Байрлах хуудас'}}
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'dropdown.value' v-for="(dropdown, indexWeb) in dropdownData" :key="indexWeb">{{dropdown.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
                <el-col :span="4" v-else>
                  <el-dropdown split-button type="text" trigger="click" @command="handleFillter">
                    {{this.page ? this.page : 'Байрлах хуудас'}}
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'dropdown.value' v-for="(dropdown, indexMobile) in dropdownDataMobile" :key="indexMobile">{{dropdown.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown  @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <banner-table :search='search' :loading='loading' :banners="banners" :pageSize='pageSize' :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler" :tab="activeTabStatus" :status="status"></banner-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import bannerTable from './components/bannerTable'

export default {
  name: 'bannerList',
  components: {
    bannerTable
  },

  data () {
    return {
      categories: [],
      channel: 'web',
      page: '',
      totalCount: 0,
      currentPage: 1,
      loading: false,
      search: '',
      pageSize: 20,
      activeTabStatus: 'all',
      banners: [],
      status: '',
      from: '',
      size: '',
      bannerChannelType: [{
        value: 'web',
        label: 'Веб'
      }, {
        value: 'mobile',
        label: 'Апп'
      }],
      fillterCategories: [],
      dropdownData: [
        {
          label: 'Бүгд',
          value: ''
        }, {
          label: 'Нүүр хуудас',
          value: 'home'
        }, {
          label: 'Бүтээгдэхүүн',
          value: 'product'
        }, {
          label: 'Брэндүүд',
          value: 'brands'
        }, {
          label: 'Брэнд',
          value: 'brand'
        }, {
          label: 'Ангилал',
          value: 'category'
        }, {
          label: 'Купон',
          value: 'coupon'
        }, {
          label: 'Хамтран ажиллах',
          value: 'co_working'
        }, {
          label: 'Онцлох',
          value: 'special'
        }, {
          label: 'Шинэ',
          value: 'new'
        }, {
          label: 'Хямдрал',
          value: 'sale'
        }, {
          label: 'Монгол брэнд',
          value: 'mongolian-brand'
        }, {
          label: 'Finish хуудас',
          value: 'finish'
        }
      ],
      dropdownDataMobile: [
        {
          label: 'Бүгд',
          value: ''
        }, {
          label: 'Нүүр хуудас',
          value: 'home'
        }, {
          label: 'Бүтээгдэхүүн',
          value: 'product'
        }, {
          label: 'Ангилал',
          value: 'category'
        }
      ],
      dropdownDataFilter: [
        {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ]
    }
  },

  created () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingTab = this.$route.query.tab
      const incomingStatus = this.$route.query.status
      // const incomingChannel = this.$route.query.channel
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.status = incomingStatus
      this.activeTabStatus = incomingTab
      // this.channel = incomingChannel
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.from = from
    this.size = size
    this.getBanners(from, size, this.search)
    this.isLoading = true
  },

  methods: {
    getCategories () {
      services.getCategoriesAsTree().then(data => {
        this.categories = data
      })
    },
    handleType () {
      this.$router.push({ name: 'bannerList', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabStatus, status: this.status } }).catch(() => {})
      this.getBanners(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabStatus)
    },
    onSearch () {
      this.$router.push({ name: 'bannerList', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabStatus, status: this.status } }).catch(() => {})
      this.getBanners(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabStatus)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'bannerList', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabStatus, status: this.status } }).catch(() => {})
      this.getBanners((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.activeTabStatus)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'bannerList', query: { page: item, size: this.pageSize, search: this.search, tab: this.activeTabStatus, status: this.status } }).catch(() => {})
      this.getBanners((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.activeTabStatus)
    },
    handleCategories () {
      this.getBanners((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search)
    },

    getBanners (from, size, search) {
      const query = '?search_text=' + search + '&status=' + this.status + '&page=' + this.page + '&from=' + from + '&size=' + size + '&channel=' + this.channel + '&categories=' + this.fillterCategories
      this.loading = true
      services.getBanners(query).then(response => {
        if (response.status === 'success') {
          this.totalCount = response.total
          this.banners = response.data
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },

    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.title.localeCompare(b.title))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.title.localeCompare(a.title))
      } else if (method === 'newest') {
        data.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.banners = this.sortMethods(data, this.banners)
    },

    handleFillter (page) {
      this.page = page
      if (page === 'product') {
        this.getCategories()
      }
      this.$router.push({ name: 'bannerList', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabStatus, fillterPage: this.page, status: this.status } }).catch(() => {})
      this.getBanners(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabStatus, this.page)
    },

    onClickTab (data) {
      if (data.label === 'All') {
        this.status = ''
      } else if (data.label === 'Active') {
        this.status = 1
      } else if (data.label === 'Expired') {
        this.status = 0
      }
      this.$router.push({ name: 'bannerList', query: { page: 1, size: this.pageSize, search: this.search, tab: this.activeTabStatus, status: this.status } }).catch(() => {})
      this.currentPage = 1
      this.getBanners(0, this.pageSize, this.search, this.activeTabStatus, this.channel)
    }
  }
}
</script>

<style>
</style>
