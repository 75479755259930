<template>
  <div>
    <el-table
    v-loading='loading'
    :data="banners"
    :show-header="true"
    style="width: 100%"
    @cell-click="clickCell">
        <el-table-column label="Зураг">
         <template slot-scope="scope">
          <el-row :gutter="10" align="left" type="flex">
            <el-col :span="12">
              <div class="image-holder">
                <img :src="scope.row.image_url">
              </div>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="Баннер код">
        <template slot-scope="scope">
          <el-row :gutter="10" align="left" type="flex">
            <!-- <div :style="`backgroundColor: ${scope.row.title_color}; width:20px; height:20px; border-radius: 10px; margin-right: 5px; border-width: 1px; border-color: gray; border-style: solid;`"></div> -->
            {{scope.row.banner_code}}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="Төлөв">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success" effect="dark">Active</el-tag>
          <el-tag v-else-if="scope.row.status === 0" type="info" effect="dark">Expired</el-tag>
          <el-tag v-else type="warning" effect="dark">{{scope.row.status}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Байрлах хуудас">
        <template slot-scope="scope">
          <div>{{scope.row.page}}</div>
        </template>
      </el-table-column>
      <el-table-column label="Эхлэх огноо">
        <template slot-scope="scope">
          <div>{{formatDate(scope.row.start_date)}}</div>
        </template>
        </el-table-column>
        <el-table-column label="Дуусах огноо">
         <template slot-scope="scope">
          <div>{{formatDate(scope.row.end_date)}}</div>
         </template>
        </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>
<script>
import helper from '../../../../helpers/helper'
export default {
  name: 'bannerTable',
  props: [
    'totalCount',
    'curentPageHandler',
    'sizeChangeHandler',
    'banners',
    'pageSize',
    'loading',
    'search',
    'currentPage',
    'tab',
    'status'
  ],

  methods: {
    clickCell (row, column, cell, event) {
      if (column.id !== 'el-table_1_column_6') {
        this.$router.push(
          {
            name: 'bannerEdit',
            params: {
              id: row.id
            },
            query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.tab, status: this.status }
          }
        )
      }
    },

    formatDate (val) {
      return helper.formatDate(val, 'YYYY/MM/DD')
    }
  }
}
</script>

<style>

</style>
